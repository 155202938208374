import { useEffect } from "react";
import { navigate } from "gatsby"
const MarketResearch = () => {
  useEffect(() => {
    navigate("/")
  }, []);
  return null;
};

export default MarketResearch;
